<template>
	<div>
		<svg
			width="140"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			class="absolute top-0"
		>
			<circle
				cx="50"
				cy="50"
				r="40"
				stroke="#D9D9D9"
				stroke-width="15"
				stroke-linecap="round"
				fill="none"
				:stroke-dasharray="`360 360`"
				pathLength="360"
			/>
		</svg>
		<svg
			width="140"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			class="-rotate-90 transition-all"
		>
			<defs>
				<linearGradient id="gradientColor" gradientTransform="rotate(90)">
					<stop offset="0%" stop-color="#ff928b" />
					<stop offset="100%" stop-color="#fdd408" />
				</linearGradient>
			</defs>
			<circle
				cx="50"
				cy="50"
				r="40"
				stroke="url(#gradientColor)"
				stroke-width="15"
				stroke-linecap="round"
				fill="none"
				:stroke-dasharray="`${calcDashLength} 360`"
				pathLength="360"
			/>
		</svg>
	</div>
</template>

<script lang="ts">
import HorizontalButtons from '@hokify/shared-components-nuxt3/lib/components/HorizontalButtons.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'CircularProgress',
	components: { HorizontalButtons },
	data() {
		return {
			currentPercentage: 0,
			calcDashLength: 0
		};
	},
	mounted() {
		this.updatePercentage();
	},
	updated() {
		this.updatePercentage();
	},
	methods: {
		updatePercentage() {
			setTimeout(() => {
				this.currentPercentage = this.percentage;
				this.calcDashLength = 360 * (this.percentage / 100);
			}, 100);
		},
		percentageChanged() {
			this.updatePercentage();
		}
	},
	props: {
		percentage: { type: Number, default: 0 }
	},
	watch: {
		percentage: [
			{
				handler: 'percentageChanged'
			}
		]
	}
});
</script>

<style scoped lang="scss">
circle {
	transition: stroke-dasharray 1s ease-in-out;
}
</style>
